<template>
  <div>
    <section class="hero">
      <div class="container">
        <div class="__content">
          <h1 class="hero-title">{{ titlePageH1 }}</h1>
          <div class="hero-text">
            Чтобы раскрывать потенциал сотрудников и команд, встраивать знания о ресурсах эмоций в рабочие процессы, улучшать взаимодействие и легче достигать результатов.
            В основе всех продуктов лежат научные разработки команды ведущих российских ученых в области эмоционального интеллекта.
            Вас интересует комплекс продуктов или не нашли оптимальное решение? Оставьте заявку, и мы поможем.
          </div>
          <div class="hero-action btn-action display--flex">
            <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-o-accent btn-sm"
            >
              Проконсультироваться
            </button>
          </div>
        </div>
      </div>
    </section>

    <div v-if="categories">
      <section
        v-for="category in categories"
        :key="category.id"
        class="products-section"
      >
        <div class="container">
          <div class="s-title">{{ category.title }}</div>
          <div
            v-if="category.products"
            class="display--grid"
            :class="category.title === 'Пакетные решения' ? 'grid-2' : 'grid-3'"
          >
            <product-card
              v-for="product of category.products"
              :key="product.id"
              :imagePath="product.back_middle_image ? product.back_middle_image : product.back_image"
              :link="category.title !== 'Пакетные решения' ? `/product/${product.slug}` : `/complex/${product.slug}`"
              :category="category.title"
              :people="product.label_people"
              :price="product.price"
              :time="product.label_duration"
              :title="product.name"
            />
          </div>
          <button class="btn btn-o-accent btn-sm" @click="openConsultRateModal = true" type="button">Запросить тариф, в зависимости от количества сотрудников.</button>
        </div>
      </section>
      <pop-up-form
          type="ConsultRate"
          commentField
          title="Получить консультацию"
          v-if="openConsultRateModal"
          @closeModal="openConsultRateModal = false"
          comment = "Запросить тариф, в зависимости от количества сотрудников"
      />
      <pop-up-form
          type="Consult"
          commentField
          title="Получить консультацию"
          v-if="openConsultModal"
          @closeModal="openConsultModal = false"
          comment = "Проконсультироваться"
      />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import ProductCard from "../../components/product-card/ProductCard.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import PopUpForm from "@/components/pop-up-form/PopUpForm.vue";
import {ref} from "@vue/reactivity";
import {useHead} from "@vueuse/head";

export default {
  components: {PopUpForm, ProductCard },
  name: "products-page",
  inject: ["backUrl"],
  setup() {
    const route = useRoute(),
      store = useStore();

    useHead({
      title: computed(() => {
        if (route.params.name === 'Для HR и T&D-специалистов') {
          return 'WAY2WEI: Продукты для HR и T&D | Оценка и развитие ЭИ сотрудников';
        } else if (route.params.name === 'Для тренеров и коучей') {
          return 'WAY2WEI: Продукты для тренеров и коучей | Оценка и развитие ЭИ';
        } else if (route.params.name === 'Для руководителей и топ-менеджеров') {
          return 'WAY2WEI: Инструменты для руководителей и топ-менеджеров | Оценка ЭИ';
        } else if (route.params.name === 'Для саморазвития') {
          return 'WAY2WEI: Цифровые инструменты для оценки и развития ЭИ';
        }
        return 'WAY2WEI: Цифровые продукты оценки и развития эмоционального интеллекта';
      }),
      meta: [
        {
          property: "description",
          content: computed(() => {
            if (route.params.name === 'Для HR и T&D-специалистов') {
              return 'Тестирование и развитие эмоционального интеллекта, отчеты и аналитика. Научные разработки. Цифровые инструменты для HRD, HRBP для снижения текучки и повышения KPI!';
            } else if (route.params.name === 'Для тренеров и коучей') {
              return 'Эксперт диагностики и развития эмоционального интеллекта — программа проф переподготовки. Усильте свой экспертный статус — работайте с цифровыми инструментами ЭИ!';
            } else if (route.params.name === 'Для руководителей и топ-менеджеров') {
              return 'Оценка эмоционального интеллекта команд, экспертное сопровождение. Внедряйте ЭИ в корпоративную стратегию — повышайте лояльность сотрудников и прибыль!';
            } else if (route.params.name === 'Для саморазвития') {
              return 'Онлайн-тесты и программы для развития эмоционального интеллекта. Профессиональная переподготовка, индивидуальные консультации. Управляйте эмоциями и достигайте целей!';
            }
            return 'Инструменты для HR, T&D и руководителей: оценка эмоционального интеллекта, аналитика и развитие. Научный подход, индивидуальные решения. Закажите консультацию!';
          })
        },
      ],
    });

    let titlePageH1 = 'Цифровые инструменты диагностики и развития эмоционального интеллекта';

    if (route.params.name === 'Для HR и T&D-специалистов') {
      titlePageH1 = 'Решения для диагностики и развития эмоционального интеллекта';
    } else if (route.params.name === 'Для тренеров и коучей') {
      titlePageH1 = 'Инструменты для оценки и развития эмоционального интеллекта';
    } else if (route.params.name === 'Для руководителей и топ-менеджеров' || route.params.name === 'Для саморазвития') {
      titlePageH1 = 'Решения для оценки и развития эмоционального интеллекта';
    }

    let openConsultRateModal = ref(false),
        openConsultModal = ref(false)

    // let tagName = ref(route.params.name);
    let generalTags = computed(() => store.state.products.generalTags),
      currentTag = computed(() =>
        generalTags.value.find((tag) => tag.title == route.params.name)
      ), tagName = computed(() => route.params.name),
      categories = computed(() => store.state.products.categories);

    onBeforeMount(() => {
      store.commit("products/setCategories", {})
      store
        .dispatch("products/getGeneralCategories", route.params.name)
        .then(() => {
          categories.value.map((category) => {
            store.dispatch("products/getProductsByTag", {
              mainTag: route.params.name,
              tag: category.title,
            });
          });
        });
    });

    watch(
      () => route.params.name,
      () => {
        if (route.path.startsWith("/products") && route.params.name) {
          store.commit("products/setCategories", {})
          store
            .dispatch("products/getGeneralCategories", route.params.name)
            .then(() => {
              categories.value.map((category) => {
                store.dispatch("products/getProductsByTag", {
                  mainTag: route.params.name,
                  tag: category.title,
                });
              });
            });
        }
      }
    );

    return {
      generalTags,
      tagName,
      currentTag,
      categories,
      openConsultModal,
      openConsultRateModal,
      titlePageH1
    };
  },
};
</script>

<style lang="scss">
.products-section {
  padding: 50px 0 50px;
}

.products-section + .products-section {
  padding-top: 0;
}

.products-section .btn {
  margin-top: 16px
}

@media (max-width: 767px) {
  .products-section .s-title {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 34px;
  }
}
</style>
